import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HideOnScroll from '../scrolls/HideOnScroll';

const FingoNavbar = ({ children }) => (
  <HideOnScroll>
    <AppBar
      elevation={0}
      position="sticky"
      sx={{ minHeight: 55, justifyContent: 'center', bgcolor: 'common.white' }}
    >
      <Toolbar>{children}</Toolbar>
    </AppBar>
  </HideOnScroll>
);

FingoNavbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FingoNavbar;
