import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Remove from '@mui/icons-material/Remove';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const LoggedOutMobileNavItems = ({ withRegister }) => {
  const classes = useStyles();
  return (
    <List>
      <ListItemButton
        component={NavLink}
        to="/login"
        activeClassName={classes.activeStyle}
        id="mobile-nav-login"
      >
        <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
          <Remove color="common.white" sx={{ transform: 'scale(3, 1)' }} />
        </ListItemIcon>
        <ListItemText
          primary="Ingresar"
          primaryTypographyProps={{
            color: 'white',
            variant: 'h6',
          }}
        />
      </ListItemButton>
      {withRegister && (
        <ListItemButton
          component={NavLink}
          to="/login?loginType=signup"
          activeClassName={classes.activeStyle}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
            <Remove color="common.white" sx={{ transform: 'scale(3, 1)' }} />
          </ListItemIcon>
          <ListItemText
            primary="Registrarse"
            primaryTypographyProps={{
              color: 'white',
              variant: 'h6',
            }}
          />
        </ListItemButton>
      )}
    </List>
  );
};

LoggedOutMobileNavItems.propTypes = { withRegister: PropTypes.bool };

LoggedOutMobileNavItems.defaultProps = { withRegister: true };

export default LoggedOutMobileNavItems;
