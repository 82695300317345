import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const RoundedRowStack = ({ children, ...props }) => (
  <Stack
    alignItems="center"
    width="100%"
    borderRadius={2}
    py={1}
    ml={0}
    spacing={1}
    direction="row"
    sx={{
      px: { md: 2, xs: 0 },
      bgcolor: { md: 'gray.A100', xs: 'transparent' },
      justifyContent: { md: 'flex-end', xs: 'space-around' },
    }}
    {...props}
  >
    {children}
  </Stack>
);

RoundedRowStack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RoundedRowStack;
